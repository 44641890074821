import React from 'react'
import { useState } from 'react';
import "./SingleFile.scss"
import angleUpIcon from "../../../Images/SVGs/angle-up-solid.svg";
import angleDownIcon from "../../../Images/SVGs/angle-down-solid.svg";
import shareIcon from "../../../Images/SVGs/share-nodes-solid.svg";
import trashIcon from "../../../Images/SVGs/trash-solid.svg";
import downloadIcon from "../../../Images/SVGs/download-solid.svg";
import sharedIcon from "../../../Images/SVGs/user-group-solid.svg";
import FileShare from "./FileShare";

const SingleFile = (props) => {
  const [fileInfoToggle, setFileInfoToggle] = useState("invisible");

  const expand = () => {
    if(fileInfoToggle==="visible"){
      document.querySelector(".file"+props.data.fileID).style.height = "60px";
      document.querySelector(".invisible"+props.data.fileID).style.display = "none";
      document.querySelector(".visible"+props.data.fileID).style.border = "none";
      document.querySelector(".file"+props.data.fileID).style.border = "none";
      document.querySelector(".file"+props.data.fileID).style.borderBottom = "1px solid #ffffff";
      setFileInfoToggle("invisible");
    }
    else{
      document.querySelector(".file"+props.data.fileID).style.height = "280px";
      document.querySelector(".file"+props.data.fileID).style.border = "2px solid #006efd";
      document.querySelector(".visible"+props.data.fileID).style.borderBottom = "1px solid white";
      document.querySelector(".invisible"+props.data.fileID).style.display = "flex";
      setFileInfoToggle("visible");
    }
  }

  const fileSize = () => {
    if (props.data.fileSize >= 1073741824) {
      return (
        <p style={{ color: "rgb(255,0,0)", fontWeight: "600", display: "inline" }}>
          {(props.data.fileSize / 1073741824).toFixed(2)} GB
        </p>
      );
    }
    else if (props.data.fileSize <= 52428800 && props.data.fileSize >= 1048576) {
      return <p style={{display: "inline"}}>{(props.data.fileSize / 1048576).toFixed(2)} MB</p>;
    } 
    else if (props.data.fileSize > 52428800) {
      return (
        <p style={{ color: "rgb(255,0,0)", fontWeight: "600", display: "inline" }}>
          {(props.data.fileSize / 1048576).toFixed(2)} MB
        </p>
      );
    }
    else if (props.data.fileSize >= 1024) {
      return <p style={{display: "inline"}}>{(props.data.fileSize / 1024).toFixed(2)} KB</p>;
    }
  }

  return (
    <>
    <FileShare data={props.data} update={props.update}></FileShare>
    <div className={"file file"+props.data.fileID}>
        <div className={"visible visible"+props.data.fileID} onClick={expand}>
            <p id="filename">{props.data.fileName}</p>
            <p id="shared">{props.data.shared?<img src={sharedIcon} alt="shared"></img>:null}</p>
            <p id="dateUploaded">{props.data.dateUploaded}</p>
            <p id="size">{fileSize()}</p>
            <button onClick={expand}><img src={fileInfoToggle==="invisible"?angleDownIcon:angleUpIcon} className={"angle"+props.data.fileID} alt="angleDown"></img></button>
        </div>
        <div className={"invisible invisible"+props.data.fileID}>
            <div id="data">
              <div id="filename2"><p><b>Filename:</b> {props.data.fileName}</p></div>
              <b>Size:</b> {fileSize()}
              <p><b>Type:</b> {props.data.fileType}</p>
              <p><b>Date Uploaded:</b> {props.data.dateUploaded}</p>
              <p><b>Shared:</b> {props.data.shared?"Yes":"No"}</p>
            </div>
            <div id="description">
                <p><b>Description:</b> {props.data.fileDescription}</p>
            </div>
            <div id="options">
              <p><b>Options</b></p><br/>
              <div className="actions">
                <button onClick={()=>document.querySelector(".link_to_file_"+props.data.fileID).click()}><img src={downloadIcon} alt="download"></img><a className={"link_to_file_"+props.data.fileID} href={props.data.downloadURL} download={props.data.downloadURL} hidden>asdasdads</a></button>
                <button onClick={()=>document.querySelector(".background-file-share"+props.data.fileID).classList.toggle("active")}><img src={shareIcon} alt="share"></img></button>
                <button onClick={()=>props.deleteFile(props.data)}><img src={trashIcon} alt="trash"></img></button>
              </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default SingleFile;
