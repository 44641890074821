import React from 'react'
import "./Header.scss"
import userIcon from "../../Images/SVGs/user-solid.svg";
import loginIcon from "../../Images/SVGs/right-to-bracket-solid.svg";
import bellIcon from "../../Images/SVGs/bell-solid.svg";
import { Link } from "react-router-dom";

const Header = () => {
  document.body.addEventListener('click', function (){document.getElementById("dropdownContent").style.display = "none"} , true);
  return (
    <div id="navContainer">
      <div id="logo">
        <Link to="/"><button><img src={require("../../Images/Icons/logo.png")} alt="logo" style={{cursor: "pointer"}}></img></button></Link>
        <Link to="/"><button style={{cursor: "pointer"}}><b>SaveIT</b></button></Link>
      </div>
      <ul id="navItems">
          <li><Link to="/">Home</Link></li>
          <li><Link to={localStorage.getItem("username")?"startSharing":"/"} onClick={()=>!localStorage.getItem("username")?document.querySelector(".background-login").classList.toggle("active"):null}>Start Sharing</Link></li>
          <li><a href="../#submitYourQueries">Submit Queries</a></li>
          <li><a href="../#aboutSaveit">About SaveIT</a></li>
          {/* <li><Link to="howToUse">How to use?</Link></li> */}
      </ul>
      <div id="loginContainer">
        <div id="login">
          {/* <button><img src={bellIcon} alt="bell" id="bellIcon"></img></button> */}
          <div id="dropdown">
            {localStorage.getItem("username")?<button id="userIconButton" onClick={()=>document.getElementById("dropdownContent").style.display = "flex"}><img src={userIcon} alt="user" id="userIcon"></img></button>:<button onClick={()=>document.querySelector(".background-login").classList.toggle("active")}><img src={loginIcon} alt="login" id="loginIcon"></img></button>}
            <div id="dropdownContent">
              <div><a onClick={()=>{document.querySelector(".background-register").classList.toggle("active")}}>Register new</a></div>
              <div><a onClick={()=>{localStorage.clear();window.location.href = '/';}}>Logout</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
