import React, { useState } from 'react'
import "./Home.scss"
import tickIcon from "../../Images/SVGs/circle-check-solid.svg"
import { Link } from "react-router-dom";
import axios from 'axios';
import env from "../../config.json";
import { success, fail } from "../Alerts/Alerts.js";


const authAxios = axios.create({
    baseURL: env.BACKEND_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
})

const Home = () => {
    const [query, setQuery] = useState({name: "", email: "", message: ""});

    const socials =  [{href:"https://www.instagram.com/durgeshm01722/", class:"instagram"}, 
    {href:"https://www.linkedin.com/in/durgesh-mahajan-99bab0212/", class:"linkedin"},
    {href:"https://github.com/durgeshm01722", class:"github"}]

    const addQuery = async (e) => {
        e.preventDefault();
        if(query.name && query.email && query.message){
          await authAxios.post("/queries", {name: query.name, email: query.email, message: query.message})
          .then((res)=>{
            setQuery({ name: "", email: "", message: ""});
            if(res.data==="success"){
              success("Sent Successfully!");
            }
            else{
              fail("An error occurred!");
            }
          })
          .catch((err)=>{
              fail("An error occurred!");
          });
        }
        else{
          alert("Enter all the fields!");
        }
      }

    return (
        <div>
            <div id="home1">
                <div id="text">
                    <h1>SaveIT</h1>
                    <p>Save here, Access anywhere!</p><br/>
                    <Link to={localStorage.getItem("username")?"startSharing":"/"} style={{textDecoration: 'none'}} onClick={()=>!localStorage.getItem("username")?document.querySelector(".background-login").classList.toggle("active"):null}><button id="startSharing">Start Sharing</button></Link>
                    <a href="#aboutSaveit" style={{textDecoration: 'none'}}><button id="howToUse">About SaveIT</button></a>
                </div>
                <div id="img">
                    <img src={require("../../Images/Icons/logo.png")} alt="logo"></img>
                </div>
            </div>

            <div id="submitYourQueries">
                <center>
                    <br/><br/>
                    <h1>Submit your queries</h1><br/><br/>
                    <p id="submitYourQueriesText">You may write down your queries related to any issues like unable to find your data, request to modify your account credentials and sharing preferences, account deactivation, etc. below.</p>

                    <form>
                        <div className="data">
                            <label className="label">Name: </label>
                            <input type="text" placeholder="Enter Name" className="txt" onChange={(e)=>setQuery({...query, name: e.target.value})} value={query.name} required></input><br/>
                        </div><br/>
                        <div className="data">
                            <label className="label">Email: </label>
                            <input type="email" placeholder="Enter Email" className="txt" onChange={(e)=>setQuery({...query, email: e.target.value})} value={query.email} required></input><br/>
                        </div><br/>
                        <div className="data">
                            <label className="label">Message: </label>
                            <textarea placeholder="Enter Message"  className="txt" id="message" onChange={(e)=>setQuery({...query, message: e.target.value})} value={query.message} required></textarea>
                        </div>
                        <div className="data">
                            <button type="submit" onClick={(e)=>addQuery(e)}><img src={tickIcon} alt="tick"></img>Submit</button>
                        </div>
                    </form>
                </center>
            </div>

            <div id="aboutSaveit">
                <center>
                    <h1>About SaveIT</h1><br/><br/>
                    <p id="aboutSaveitText">
                        SaveIT is a Web Application developed to help users save their content (Text/Files)
                        and access/retrieve it anywhere on any system over the internet.<br/>
                    </p><br/><br/><br/><br/>
                    {socials.map(item => {
                        return <a href={item.href} className={`fa fa-${item.class}`} target="new" key={item.href}/>
                    })}
                    <br/><br/>
                    <p style={{fontSize:"20px", fontWeight:"650"}}>Made with 💙 by Durgesh</p>
                    <p>Feel free to share your queries and feedbacks on my email</p>
                    <a href="mailto:durgeshmahajan1722@gmail.com"  style={{textDecoration:"none", color: "rgb(42, 115, 240)", fontSize:"15px"}}>durgeshmahajan1722@gmail.com</a>
                    <br/><br/>
                </center>
            </div>
        </div>
    )
}

export default Home;