import './App.css';
import Header from "./components/Header/Header"
import Home from "./components/Home/Home"
import Footer from "./components/Footer/Footer"
import LoginDialog from "./components/Login/LoginDialog"
import RegisterDialog from "./components/Login/RegisterDialog"
import SharingSection from "./components/SharingSection/SharingSection"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
      <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoginDialog></LoginDialog>
      <RegisterDialog></RegisterDialog>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/startSharing" element={<SharingSection/>} />
      </Routes>
      <Footer></Footer>
      </BrowserRouter>
  );
}

export default App;
