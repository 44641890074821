import React from 'react'
import { useEffect, useState } from 'react';
import "./Text.scss"
import plusIcon from "../../../Images/SVGs/plus-solid.svg";
import TextUpload from './TextUpload';
import axios from 'axios';
import SingleText from "./SingleText.js";
import env from "../../../config.json";
import { success, fail } from "../../Alerts/Alerts.js";


const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
})

const Text = () => {
  const [data, setData] = useState([]);

  const getData = async () => {await authAxios.get("/texts").then((res)=>{
      if(res.data==="not authorized!"){
        fail("Not Authorized!");
      }
      else{
        setData(res.data);
      }
    })
  }

  useEffect(() => {
    getData();
  }, []);

  const deleteText = (text)=>{
    authAxios.delete(`/texts/${text.textID}`).then((res)=>{
      if(res.data==="success"){
        success("Deleted Successfully!");
      }
      else{
        fail("An error occurred!");
      }
      setData(data.filter((e)=>{
        return e !== text;
      }))
    })
  }
  

  return (
    <>  
    <TextUpload update={getData}></TextUpload>
    <div id="textSection">
        <div id="button">
          <button onClick={()=>{document.querySelector(".background-text").classList.toggle("active");}}><img src={plusIcon} alt="plus"></img><p>New Post</p></button>
        </div>
        <div id="texts">
          <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width: "auto"}}>
            {data.reverse().map((item, key)=>{
              return(
                <SingleText item={item} key={key} deleteText={deleteText} update={getData}></SingleText>
              )
            })}
          </div>
        </div>
    </div>
    </>
  )
}

export default Text;
