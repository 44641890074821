import React from 'react'
import { useState } from 'react';
import "./FileUpload.scss"
import Tilt from "react-parallax-tilt";
import tickIcon from "../../../Images/SVGs/circle-check-solid.svg";
import closeIcon from "../../../Images/SVGs/plus-solid.svg";
import fileIcon from "../../../Images/SVGs/file-solid.svg";
import env from "../../../config.json";
import axios from 'axios';
import { success, fail } from "../../Alerts/Alerts.js";
import { initializeApp } from "firebase/app";
import { getDownloadURL, getMetadata, getStorage, ref, uploadBytesResumable } from "firebase/storage";

const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }
})

var firebaseConfig = {}
var fileInfo = {
  name: "",
  downloadURL: "",
  size: 0,
  type: ""
}

authAxios.get("/firebaseConfig")
.then((res)=>{
  firebaseConfig = res.data;
})
.catch((err)=>{
  console.log(err.message);
})

const FileUpload = (props) => {
  const [file, setFile] = useState({});
  const [desc, setDesc] = useState("");
  const [progress, setProgress] = useState(0);

  const addFile = async (e) => {
    e.preventDefault();
    const now = new Date();
    if(file.name && desc){
      if (file.size>52428800){
        alert("File Size Exceeded!!!");
      }
      else{
        fileInfo.name = file.name;
        const app = initializeApp(firebaseConfig);
        const storage = getStorage(app);
        const fileRef = ref(storage, `${localStorage.getItem("username")}/${file.name}`);
        await getDownloadURL(fileRef)
          .then((url)=>{
            fail("File already present!");
          })
          .catch((err) => {
            document.querySelector(".progressContainer").classList.add("active");
            document.querySelector(".uploading").classList.add("active");
            document.querySelector(".progress").classList.add("active");
          const uploadTask = uploadBytesResumable(fileRef, file);
          uploadTask.on("state_changed", (snapshot)=>{
            fileInfo.size = snapshot.totalBytes;
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(progress);
          },
          (err)=>{},
          ()=>{
              getDownloadURL(uploadTask.snapshot.ref).then(async (dURL) => {
              fileInfo.downloadURL = dURL;
              await getMetadata(fileRef)
                .then((metaData)=>{
                  fileInfo.type = metaData.contentType;
                })
                .catch((err)=>{
                })
              const fileData = {
                fileName: fileInfo.name,
                fileSize: fileInfo.size,
                fileType: fileInfo.type,
                dateUploaded: `${now.getDate()}-${now.getMonth()+1}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
                fileDescription: desc,
                downloadURL: fileInfo.downloadURL
              };
              await authAxios.post("/files", fileData)
              .then((res)=>{
                if(res.data==="success"){
                  success("Uploaded Successfully!");
                  document.querySelector(".background-file").classList.toggle("active")
                  props.update();
                  setFile(undefined);
                  setDesc("");
                  document.querySelector(".progressContainer").classList.remove("active");
                  document.querySelector(".uploading").classList.remove("active");
                  document.querySelector(".progress").classList.remove("active");
                }
                else{
                  fail("An error occurred!");
                  document.querySelector(".progressContainer").classList.remove("active");
                  document.querySelector(".uploading").classList.remove("active");
                  document.querySelector(".progress").classList.remove("active");
                }
              })
            });
          })
        })
      }
    } 
    else{
      alert("Enter all the fields!");
    }
  }

  const fileSize = () => {
    if (file.size >= 1073741824) {
      return (
        <p style={{ color: "rgb(255,0,0)", fontWeight: "600", fontSize: "12px"}}>
          {(file.size / 1073741824).toFixed(2)} GB
        </p>
      );
    } else if (file.size <= 52428800 && file.size >= 1048576) {
      return <p style={{fontSize: "12px"}}>{(file.size / 1048576).toFixed(2)} MB</p>;
    } else if (file.size > 52428800) {
      return (
        <p style={{ color: "rgb(255,0,0)", fontWeight: "600", fontSize: "12px"}}>
          {(file.size / 1048576).toFixed(2)} MB
        </p>
      );
    } else if (file.size >= 1024) {
      return <p style={{fontSize: "12px"}}>{(file.size / 1024).toFixed(2)} KB</p>;
    }
  }

  return (
    <div className="background-file">
         <Tilt
          className="tilt"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glareEnable={false}
        >
        <div id="fileUploadContainer">
            <button id="close" onClick={()=>{document.querySelector(".background-file").classList.toggle("active")}}><img src={closeIcon} alt="close"></img></button>
            <form>
                <div id="fileSelectContainer">
                  <p>Select File: </p>
                  <div id="fileSelect">
                    <input type="file" id="fileInput" onChange={(e) => {setFile(e.target.files[0]);
                    document.getElementById("filename").style.visibility ="visible";}}/>
                    <label htmlFor="fileInput"><img src={fileIcon} alt="file"></img>Choose a file</label>
                    <span id="filename"><p style={{fontSize: "12px"}}>{file!==undefined?file.name:""}</p></span>
                    <span id="filesize">{file!==undefined?fileSize():null}</span>
                    
                  </div>
                  <p style={{color: "rgb(255,0,0)", fontWeight: "600", fontSize: "12px"}}>File Size Limit: 50 MB</p>
                </div><br/>
                <div id="addDescriptionContainer">
                    <p>Add Description: </p>
                    <textarea placeholder="Add Description" id="addDescriptionTxt" onChange={(e)=>setDesc(e.target.value)} value={desc}></textarea>
                </div>
                <div id="uploadButton">
                  <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", width: "80%", height: "50px"}}>
                    <p className="uploading">Uploading...</p><p className="progress">{progress}%</p>
                    {/* <div className="loader"></div> */}
                  </div>
                  <button onClick={(e)=>addFile(e)}><img src={tickIcon} alt="tick"></img>Upload</button>
                </div>
                <div className="progressContainer">
                  <div className="progressBar" style={{width: `${progress}%`}}>

                  </div>
                </div>
            </form>
        </div>
        </Tilt>
    </div>
  )
}

export default FileUpload;