import React, { useState, useEffect } from 'react'
import "./File.scss"
import uploadIcon from "../../../Images/SVGs/upload-solid.svg";
import SingleFile from "./SingleFile";
import FileUpload from './FileUpload';
import axios from 'axios';
import env from "../../../config.json";
import { success, fail } from "../../Alerts/Alerts.js";

const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
})

const File = () => {
    const [data, setData] = useState([]);

    const getData = async () => {await authAxios.get("/files").then((res)=>{
        if(res.data==="not authorized!"){
          fail("Not Authorized!");
        }
        else{
          setData(res.data);
        }
      })
    }
  
    useEffect(() => {
      getData();
    }, []);
  
    const deleteFile = (file)=>{
      authAxios.delete(`/files/${file.fileID}`).then((res)=>{
        if(res.data==="success"){
          success("Deleted Successfully!");
        }
        else{
          fail("An error occurred!");
        }
        setData(data.filter((e)=>{
          return e !== file;
        }))
      })
    }

    return (
    <>
        <FileUpload update={getData}></FileUpload>
        <div id="fileSection">
            <div id="button">
            <button id="fileUploadButton" onClick={()=>{document.querySelector(".background-file").classList.toggle("active")}}><img src={uploadIcon} alt="upload"></img><p>Upload</p></button>
            </div>
            <div id="files">
              <div id="filesContainer">
                {data.reverse().map((item, key)=>{
                    return(
                        <SingleFile data={item} key={key} deleteFile={deleteFile} update={getData}></SingleFile>
                    )
                })}
              </div>
            </div>
        </div>
    </>
  )
}

export default File;