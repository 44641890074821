import React from 'react'
import { useState } from 'react';
import "./RegisterDialog.scss"
import Tilt from "react-parallax-tilt";
import closeIcon from "../../Images/SVGs/plus-solid.svg";
import inIcon from "../../Images/SVGs/right-to-bracket-solid.svg";
import axios from 'axios';
import { success, fail } from "../Alerts/Alerts.js";
import env from "../../config.json";

const RegisterDialog = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const registerUser = async (e) => {
    e.preventDefault();
    if(username && password && name && email){
      await axios.post(`${env.BACKEND_URL}/register`, {username: username, password: password, name: name, email: email})
      .then((res)=>{
        if(res.data==="user already exists"){
          fail("User already exists!");
        }
        else{
          success("Registered successfully!");
          localStorage.setItem("username", res.data.username);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("token", res.data.token);
          document.querySelector(".background-register").classList.toggle("active");
          window.location.href = '/';
        }
      })
      .catch((err)=>{
        fail("An error occurred!");
      });
    }
    else{
      alert("Fill all the fields!");
    }
  }

  return (
    <div className="background-register">
         <Tilt
          className="tilt"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glareEnable={false}
        >
          <div id="registerDialogContainer">
              <button id="close" onClick={()=>{document.querySelector(".background-register").classList.toggle("active")}}><img src={closeIcon} alt="close"></img></button>
              <div id="rightSide">
                <form>
                    <div id="nameContainer">
                        <p>Name: </p>
                        <input type="text" className="credentialsInput" onChange={(e)=>setName(e.target.value)} required></input>
                    </div>
                    <div id="usernameContainer">
                        <p>Username: </p>
                        <input type="text" className="credentialsInput" onChange={(e)=>setUsername(e.target.value)} required></input>
                    </div>
                    <div id="emailContainer">
                        <p>Email: </p>
                        <input type="email" className="credentialsInput" onChange={(e)=>setEmail(e.target.value)} required></input>
                    </div>
                    <div id="passwordContainer">
                        <p>Password: </p>
                        <input type="password" className="credentialsInput" onChange={(e)=>setPassword(e.target.value)} required></input>
                    </div>
                    <div id="registerButton">
                        <button onClick={(e)=>registerUser(e)}><img src={inIcon} alt="login"></img>Register</button>
                    </div><br/>
                    <div id="signUpLink">
                        <p>Already a user?</p>
                        <a onClick={()=>{document.querySelector(".background-login").classList.toggle("active")
                    document.querySelector(".background-register").classList.toggle("active")}}>Login</a>
                    </div>
                </form>
              </div>
          </div>
        </Tilt>
    </div>
  )
}

export default RegisterDialog;