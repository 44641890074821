import React, { useEffect, useState } from 'react'
import "./Footer.scss"
import { Link } from "react-router-dom";
import env from "../../config.json";
import axios from 'axios';

const Footer = () => {
  const [count, setCount] = useState();

  useEffect(() => {
    axios.get(`${env.BACKEND_URL}/count`).then((res)=>{
    setCount(res.data);
    })
  }, [])
  

  return (
    <div id="footer">
      <div id="links">
        <Link to="/" style={{width: "fit-content"}}>Home</Link>
        <a href="../#submitYourQueries" style={{width: "fit-content"}}>Submit Queries</a>
        <a href="../#aboutSaveit" style={{width: "fit-content"}}>About SaveIT</a>
        {/* <Link to="contactMe" style={{width: "fit-content"}}>Contact Me</Link> */}
      </div><br/>
      <div id="copyright">
        <center>
          <p><b>Total Visits:</b> {count}</p>
          <p>Copyright &#169; 2022 | SaveIT</p>
        </center>
      </div>
    </div>
  )
}

export default Footer;