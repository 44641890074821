import React from 'react'
import { useState, useEffect } from 'react';
import "./FileShare.scss"
import Tilt from "react-parallax-tilt";
import tickIcon from "../../../Images/SVGs/circle-check-solid.svg";
import closeIcon from "../../../Images/SVGs/plus-solid.svg";
import { success, fail } from "../../Alerts/Alerts.js";
import axios from 'axios';
import env from "../../../config.json";


const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
})


const FileShare = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({username: "", name: ""});
  const [filteredUsers, setFilteredUsers] = useState([]);


  const getUsers = async () => {await authAxios.get("/users").then((res)=>{
    if(res.data==="not authorized!"){
      fail("Not Authorized!");
    }
    else{
      setUsers(res.data);
      setFilteredUsers(res.data);
    }
  })
}

  useEffect(() => {
    getUsers();
  }, []);

  const shareFile = async (e) => {
    e.preventDefault();
    if(selectedUser.username){
      await authAxios.post(`/shareFile/${props.data.fileID}`, {sharedTo: selectedUser.username})
      .then((res)=>{
        document.querySelector(".background-file-share"+props.data.fileID).classList.toggle("active");
        props.update();
        setSelectedUser({username: "", name: ""});
        if(res.data==="success"){
          success("Shared Successfully!");
        }
        else if(res.data==="file is already shared to user"){
          fail("File is already shared to user!")
        }
        else{
            console.log("error1");
          fail("An error occurred!");
        }
      })
      .catch((err)=>{
        console.log(err.message);
          fail("An error occurred!");
      });
    }
    else{
      alert("Please select a user to share!");
    }
  }

  const filterUsers = (e)=> {
    setFilteredUsers(users.filter((item)=>{
        return item.username.toLowerCase().indexOf(e.target.value.toLowerCase())!==-1;
    }))
    if(filteredUsers[0]===undefined){
        document.getElementById("usersSelectId").style.width = "0px";
    }
    else{
        document.getElementById("usersSelectId").style.width = "fit-content";
    }
  }

  return (
    <>
    <div className={"background-file-share background-file-share"+props.data.fileID}>
         <Tilt
          className="tilt"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glareEnable={false}
        >
          <div id="fileShareContainer">
              <button id="close" onClick={()=>{document.querySelector(".background-file-share"+props.data.fileID).classList.toggle("active")}}><img src={closeIcon} alt="close"></img></button>
              <div id="share">
                <p>Choose the user to share file to:</p>
                <div id="shareContainer">
                  <input className="selectedUser" type="text" required onChange={(e)=>{
                    setSelectedUser(e);
                    filterUsers(e); document.querySelector(".usersSelect"+props.data.fileID).classList.add("active")
                  }
                  } onClick={()=>{document.querySelector(".usersSelect"+props.data.fileID).classList.toggle("active");setFilteredUsers(users)}} value={selectedUser.username}>
                  </input>
                  <div className={"usersSelect usersSelect"+props.data.fileID} id="usersSelectId">
                    {filteredUsers.map((item, key)=>{return (
                      <div className="singleUser" key={key} onClick={()=>{setSelectedUser({username: item.username, name: item.name});document.querySelector(".usersSelect"+props.data.fileID).classList.toggle("active")}}>
                        <h3>{item.name}</h3>
                        <p>{item.username}</p>
                      </div>
                    )})}
                  </div>
                </div>
              </div>
              <div id="shareInfo" style={{marginLeft: "5%", marginTop: "0%"}}>
                <b>Note: </b>
                <p>1. This action can't be undone, so be sure before proceeding.</p>
                <p>2. The person to whom you are sharing this resource will have a complete control over it. He/She can modify, delete, reshare and copy the resource.</p>
              </div>
              <div id="uploadButton">
                      <button onClick={(e)=>shareFile(e)}><img src={tickIcon} alt="tick"></img>Share</button>
              </div>
          </div>
        </Tilt>
    </div>
    </>
  )
}

export default FileShare;