import React from 'react'
import { useState } from 'react'
import "./SharingSection.scss"
import Text from "./TextUpload/Text"
import File from "./FileUpload/File"

const SharingSection = () => {
  const [mode, setMode] = useState("file");

  return (
    <div id="sharingSection">
      <div id="sharingChoice">
        <button onClick={()=>{setMode("file")}} style={{background: mode==="file"?"#293d58":"none"}}>Files</button>
        <button onClick={()=>{setMode("text")}} style={{background: mode==="text"?"#293d58":"none"}}>Text Post</button>
      </div>
      <div id="sharingContainer">
        <div id="content">
          {mode==="file"?<File></File>:<Text></Text>}
        </div>
      </div>
    </div>
  )
}

export default SharingSection;