import React from 'react'
import { useState } from 'react';
import "./LoginDialog.scss"
import Tilt from "react-parallax-tilt";
import closeIcon from "../../Images/SVGs/plus-solid.svg";
import inIcon from "../../Images/SVGs/right-to-bracket-solid.svg";
import userIcon from "../../Images/SVGs/user-solid.svg";
import axios from 'axios';
import { success, fail } from "../Alerts/Alerts.js";
import env from "../../config.json";

const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
})

const LoginDialog = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    if(username && password){
      await authAxios.post("/login", {username: username, password: password})
      .then((res)=>{
        if(res.data==="invalid user"){
          fail("Invalid User!");
        }
        else{
          success("Logged in successfully!");
          localStorage.setItem("username", res.data.username);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("token", res.data.token);
          document.querySelector(".background-login").classList.toggle("active");
          window.location.href = '/';
        }
      })
      .catch((err)=>{
          fail("An error occurred!");
      });
    }
    else{
      alert("Fill all the fields!");
    }
  }

  const demoUser = async (e)=> {
    e.preventDefault();
    await axios.post(`${env.BACKEND_URL}/login`, {username: "demouser", password: "demouser"}, { 
      headers: { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRlbW91c2VyIiwiaWF0IjoxNjY0MTMxOTk0fQ.tGloYUIRqgt8TU8THTKIWlwLlHepJRmL7EwyKGIj8ik"}})
    .then((res)=>{
        success("Logged in successfully!");
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("name", res.data.name);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("token", res.data.token);
        document.querySelector(".background-login").classList.toggle("active");
        window.location.href = '/';
    })
    .catch((err)=>{
      console.log(err.message)
        fail("An error occurred!");
    });
  }

  return (
    <div className="background-login">
         <Tilt
          className="tilt"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glareEnable={false}
        >
          <div id="loginDialogContainer">
              <button id="close" onClick={()=>{document.querySelector(".background-login").classList.toggle("active")}}><img src={closeIcon} alt="close"></img></button>
              <div id="rightSide">
                <form>
                    <div id="usernameContainer">
                        <p>Username: </p>
                        <input type="text" className="credentialsInput" onChange={(e)=>setUsername(e.target.value)} required></input>
                    </div><br/>
                    <div id="passwordContainer">
                        <p>Password: </p>
                        <input type="password" className="credentialsInput" onChange={(e)=>setPassword(e.target.value)} required></input>
                    </div><br/>
                    <div id="loginButton">
                        <button onClick={(e)=>loginUser(e)}><img src={inIcon} alt="login"></img>Login</button>
                        <button onClick={(e)=>demoUser(e)}><img src={userIcon} alt="demo"></img>Demo User</button>
                    </div><br/>
                    <div id="signUpLink">
                        <p>Not signed up yet?</p>
                        <a onClick={()=>{document.querySelector(".background-login").classList.toggle("active");
                    document.querySelector(".background-register").classList.toggle("active")}}>Sign Up</a>
                    </div>
                </form>
              </div>
          </div>
        </Tilt>
    </div>
  )
}

export default LoginDialog;