import React from 'react'
import { useState } from 'react';
import "./TextUpload.scss"
import Tilt from "react-parallax-tilt";
import tickIcon from "../../../Images/SVGs/circle-check-solid.svg";
import closeIcon from "../../../Images/SVGs/plus-solid.svg";
import { success, fail } from "../../Alerts/Alerts.js";
import axios from 'axios';
import env from "../../../config.json";


const authAxios = axios.create({
  baseURL: env.BACKEND_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
})


const TextUpload = (props) => {
  const [uploadText, setUploadText] = useState({ title: "", content: ""});

  const addText = async (e) => {
    e.preventDefault();
    if(uploadText.title && uploadText.content){
      await authAxios.post("/texts", {textTitle: uploadText.title, textContent: uploadText.content})
      .then((res)=>{
        document.querySelector(".background-text").classList.toggle("active");
        props.update();
        setUploadText({ title: "", content: ""});
        if(res.data==="success"){
          success("Saved Successfully!");
        }
        else{
          fail("An error occurred!");
        }
      })
      .catch((err)=>{
          fail("An error occurred!");
      });
    }
    else{
      alert("Enter all the fields!");
    }
  }

  return (
    <>
    <div className="background-text">
         <Tilt
          className="tilt"
          tiltMaxAngleX={10}
          tiltMaxAngleY={10}
          perspective={1000}
          transitionSpeed={1000}
          scale={1}
          gyroscope={true}
          glareEnable={false}
        >
          <div id="textUploadContainer">
              <button id="close" onClick={()=>{document.querySelector(".background-text").classList.toggle("active")}}><img src={closeIcon} alt="close"></img></button>
              <form>
                  <div id="textTitleContainer">
                    <p>Add Title: </p>
                    <input placeholder="Add Title" type="text" id="title" onChange={(e)=>setUploadText({...uploadText, title: e.target.value})} value={uploadText.title} required></input>
                  </div><br/>
                  <div id="addContentContainer">
                      <p>Add Text Body/Content: </p>
                      <textarea placeholder="Add Content" id="addContentTxt" onChange={(e)=>setUploadText({...uploadText, content: e.target.value})} value={uploadText.content} required></textarea>
                  </div><br/>
                  <div id="uploadButton">
                      <button onClick={(e)=>addText(e)}><img src={tickIcon} alt="tick"></img>Save</button>
                  </div>
              </form>
          </div>
        </Tilt>
    </div>
    </>
  )
}

export default TextUpload;