import React from 'react';
import "./SingleText.scss";
import editIcon from "../../../Images/SVGs/pen-to-square-solid.svg";
import shareIcon from "../../../Images/SVGs/share-nodes-solid.svg";
import trashIcon from "../../../Images/SVGs/trash-solid.svg";
import sharedIcon from "../../../Images/SVGs/user-group-solid.svg";
import TextEdit from "./TextEdit";
import TextShare from "./TextShare";

const SingleText = (props) => {
  return (
    <>
      <TextEdit data={props.item} update={props.update}></TextEdit>
      <TextShare data={props.item} update={props.update}></TextShare>
      <div className={`post post${props.item.textID}`}>
          {props.item.shared?<img src={sharedIcon} id="sharedIcon" alt="shared"></img>:null}
          <h1>{props.item.textTitle}</h1><br/>
          <p>{props.item.textContent}</p>
          <div className="actions">
              <button onClick={()=>document.querySelector(".background-text-edit"+props.item.textID).classList.toggle("active")}><img src={editIcon} alt="edit"></img></button>
              <button onClick={()=>document.querySelector(".background-text-share"+props.item.textID).classList.toggle("active")}><img src={shareIcon} alt="share"></img></button>
              <button onClick={()=>props.deleteText(props.item)}><img src={trashIcon} alt="trash"></img></button>
          </div>
      </div>
    </>
  )
}

export default SingleText;